<script setup lang="ts">
import { useImage } from '#image/composables'
import { computed } from 'vue'

const props = withDefaults(defineProps<{
  showPartners?: boolean
  temoignageInsteadOfBao?: boolean
  commentaires?: {
    name: string
    text: string
  }[]
  bgQuote?: string
  hidePlatformCtaBanner?: boolean
  hideTemoignages?: boolean
  quoteOpacity?: '30' | '70'
}>(), {
  showPartners: false,
  temoignageInsteadOfBao: false,
  commentaires: () => [],
  bgQuote: '/img/forest.jpg',
  hidePlateformCtaBanner: false,
  hideTemoignages: false,
  quoteOpacity: '30',
})

const $img = useImage()

const valeurs = [
  'Sagesse',
  'Lien',
  'Bienveillance',
  'Facilitation',
  'Transmission',
]

const backgroundImageForest = computed(() => `url(${$img(props.bgQuote, {
  quality: 80,
  format: 'webp',
  width: 1100,
})})`)

const commentaires = [
  {
    name: 'Nathalie CORTI',
    text: 'Au travers d’Eliah, j’ai trouvé un dispositif riche et puissant ✨ La Roue de l’ascension apporte un éclairage précieux sur notre évolution. Les modules de formation présentent les différentes étapes et notions de manière très claire et au travers d’une pédagogie qui facilite l’appropriation. La boîte à outils est d’une incroyable richesse, tant au niveau des thématiques abordées, que des natures d’exercices proposés. La plateforme intuitive présente le tout dans un écrin au magnifique design. Enfin Anaïs et Meghanne portent et transmettent la roue de l’ascension avec professionnalisme et passion. Je ne peux que recommander !',
  },
  {
    name: 'Estelle BEGUIN',
    text: 'Une très belle découverte. Plateforme intuitive, esthétique, et surtout incroyablement fournie ! La pédagogie est bien articulée et très éclairante. La boîte à outils contient une multitude d’exercices puissants et variés qui donnent un côté ludique à mes accompagnements. L’expérience avec Anaïs et Meghanne est précieuse, en soutien individuel ou collectif. La plateforme d’Eliah accompagne désormais ma pratique au quotidien. Validée',
  },
  {
    name: 'Cyril MONTERO',
    text: ' Lorsque sagesse et progrès se rencontrent, le résultat est bluffant, et la plateforme Eliah en est l’illustration. Avec une formation aux différents modules intégrant des vidéos concises et concrètes construites avec générosité par Anaïs et Meghanne, ensuite avec des tests d’intériorité faciles à partager, et enfin  avec l’accès à une boîte à outils structurée et riche en informations permettant une expérience efficace et fructueuse  pour mes accompagnements. Depuis un an, c’est devenu un réflexe de partager ces outils à mes bénéficiaires. J\'ai testé et recommande vivement Eliah, un allié précieux dans ma pratique de coaching. Merci à Anaïs et Meghanne pour leur présence et réactivité.',
  },
  {
    name: 'Noémi YAMODO',
    text: 'Superbe formation. Enrichissante. Ouverture d\'esprit. Elle nous aide à aller de l\'avant dans nos projets professionnels et personnels. La méthode et la plateforme sont devenues indispensables à ma profession et mes projets d\'éducatrice spécialisée intégrant le développement personnel. Les jeunes des associations où j\'interviens en bénéficient, cela leur parle et j\'en suis ravie.',
  },
]
</script>

<template>
  <div class="w-full">
    <div class="w-full flex flex-col items-center justify-center from-lemon to-sunset bg-gradient-to-b">
      <h3 class="mt-8 text-center text-xl text-black">
        <span class="text-sunset uppercase">Nos</span><br>
        <span class="text-2xl font-laviossa">valeurs</span>
      </h3>

      <div class="grid my-10 items-center justify-center gap-4 text-slate-700 tracking-widest font-gravity xl:grid-cols-5 compact-container xl:gap-2 children:uppercase">
        <div v-for="valeur in valeurs" :key="valeur" class="flex items-center justify-start gap-4 xl:justify-center">
          <NuxtImg src="/img/eliah_picto-violetfonce.png" width="40" height="40" alt="Picto Eliah Sunset" format="webp" quality="80" />
          {{ valeur }}
        </div>
      </div>
    </div>

    <div v-if="!temoignageInsteadOfBao && !hidePlatformCtaBanner" class="w-full flex items-center justify-center from-[#3F375C] to-[#1C1439] bg-gradient-to-b">
      <div class="flex flex-col items-center lt-lg:mb-12 lt-lg:gap-6 compact-container lg:flex-row">
        <NuxtImg
          class="flyers h-80 w-full object-cover 2xl:h-100 lg:w-1/2"
          src="/img/flyers-bao.png"
          sizes="sm:50vw md:50vw lg:50vw xl:50vw"
          alt="Picto Eliah Sunset"
          format="webp"
          quality="80"
        />

        <div class="w-full flex flex-col items-center lg:w-1/2 lg:items-end">
          <h3 class="text-center text-xl text-lavender tracking-widest font-ubuntu uppercase lg:text-end 2xl:text-2xl">
            <slot name="platform-cta-banner-title">
              Maitrisez la profondeur de vos séances
            </slot>
          </h3>

          <h2 class="mt-2 text-center text-6 text-white font-laviossa lg:text-end 2xl:text-7">
            <slot name="platform-cta-banner-subtitle">
              <span>avec un ensemble d'outils essentiels</span>
            </slot>
          </h2>

          <VBtn theme="void" class="mt-10" to="/plateforme-accompagnement">
            <span class="text-center text-center text-4 tracking-widest font-ubuntu">
              Découvrez gratuitement les outils
            </span>
          </VBtn>
        </div>
      </div>
    </div>

    <VSectionCommentaires
      v-if="temoignageInsteadOfBao && !hideTemoignages"
      :commentaires="commentaires"
    />

    <div class="bg-forest h-sm w-full flex items-center bg-cover text-void">
      <div
        class="h-full w-full flex items-center bg-lavender-400"
        :class="{
          'bg-opacity-30': quoteOpacity === '30',
          'bg-opacity-70': quoteOpacity === '70',
        }"
      >
        <div class="flex grow justify-center">
          <div class="h-[3px] w-full bg-[#fcea10]" />
        </div>
        <div class="flex flex-col items-center justify-center gap-6 font-laviossa compact-container">
          <h3 class="text-center text-3xl">
            <slot name="quote">
              Se réunir est un début, rester ensemble est un progrès, <br>
              travailler ensemble est la réussite.
            </slot>
          </h3>
          <h4 class="mt-2 text-2xl text-white">
            <slot name="quote-author">
              Henry Ford
            </slot>
          </h4>
        </div>
        <div class="flex grow justify-center">
          <div class="h-[3px] w-full bg-[#fcea10]" />
        </div>
      </div>
    </div>

    <div v-if="showPartners" class="w-full flex items-center justify-center bg-white py-10">
      <div class="grid grid-cols-1 items-center justify-center gap-4 lg:grid-cols-3 compact-container">
        <div>
          <h4 class="text-center text-xl">
            <span class="text-gray uppercase">Découvrez nos</span><br>
            <span class="text-2xl text-void font-laviossa">partenaires</span>
          </h4>
        </div>

        <NuxtImg class="mx-auto block" src="/img/dera_consulting.png" height="100" alt="Picto Eliah Sunset" format="webp" quality="80" />
        <NuxtImg class="mx-auto block" src="/img/coaxial.png" height="100" alt="Picto Eliah Sunset" format="webp" quality="80" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-forest {
  background-image: v-bind('backgroundImageForest');
  background-position: center;
}
</style>
